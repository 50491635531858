<template>
  <CRow>
    <CCol col="12">
      <div class="row">
        <CCol sm="12" class="align-self-center">
          <div class="text-sm-right mt-3 mt-sm-0">
            <CButtonGroup class="file-export">
              <CButton color="secondary" disabled>
                {{ $lang.buttons.export.title }}
              </CButton>
              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.export.pdf"
                  v-on:click="
                  exports(self, 'pdf', 'GAC Categories Post Report', 'GAC Categories Post Report')
                "
              >
                <i class="fas fa-file-pdf"></i>
              </CButton>
              <VueJsonToCsv
                  class="btn btn-success"
                  v-c-tooltip="$lang.buttons.export.excel"
                  :json-data="json_data"
                  :labels="json_label"
                  :csv-title="json_title"
              >
                <i class="fas fa-file-excel"></i>
              </VueJsonToCsv>
            </CButtonGroup>
            <BeforeFilter
                :filterShow="toggleFilterValue"
                @getFilterToggle="switchFilter"
            ></BeforeFilter>
          </div>
        </CCol>
      </div>
      <transition name="fade">
        <CRow v-if="toggleFilterValue">

          <CCol sm="3">
            <div class="form-group">
              <label class="form__label">Registered As</label>
              <v-select :options="registeredTypes" @option:selected="getUsers"
                        v-model="filter.registeredTypes"></v-select>
            </div>
          </CCol>

          <CCol sm="3">
            <div class="form-group">
              <label class="form__label">Name</label>
              <v-select :options="list" v-model="filter.list"></v-select>
            </div>
          </CCol>

          <CCol md="3">
            <div class="form-group">
              <label class="form__label">{{ $lang.gac.crud.post }}</label>
              <v-select :options="postOptions" v-model="filter.post"></v-select>
            </div>
          </CCol>
          <CCol sm="3">
            <div class="form-group">
              <label class="form__label">Reasons</label>
              <v-select :options="reasons" @option:selected="getUsers" v-model="filter.reason"></v-select>
            </div>
          </CCol>
          <CCol sm="3">
            <div class="form-group" style="margin-top: 30px">
              <CButton
                  type="submit"
                  size="sm"
                  color="primary"
                  @click="applyFilter()"
                  v-c-tooltip="$lang.common.filter.button.filter"
              >
                {{ $lang.common.filter.button.filter }}
              </CButton>
              &nbsp;
              <CButton
                  color="danger"
                  size="sm"
                  @click="resetFilter()"
                  v-c-tooltip="$lang.common.filter.button.cancel"
              >
                {{ $lang.common.filter.button.cancel }}
              </CButton>
            </div>
          </CCol>
        </CRow>
      </transition>
      <v-server-table
          :columns="columns"
          :url="url"
          :options="options"
          ref="myTable"
          @loaded="exports(self, 'Excel', 'GAC Categories Post Report', 'GAC Categories Post Report')"
      >
        <template #firstName="data">
          <Avatar
              :image="data.row.avatar"
              :content="data.row.firstName"
              :isProfileListPage="true"
              :length="20"
          />
        </template>
        <template #post="data">
              <span :title="trimfunction(data.row.post, 20)">{{
                  trimfunction(data.row.post, 20)
                }}</span>
        </template>
        <template #description="data">
              <span :title="trimfunction(data.row.description, 20)">{{
                  trimfunction(data.row.description, 20)
                }}</span>
        </template>
        <template #createdAt="data">
          {{ dateTimeUTCtoLocalFormat(data.row.createdAt) }}
        </template>
        <template #company="data">
            <span v-if="data.row.registeredType==='Individual'">
                --
            </span>
          <span v-else-if="data.row.registeredType==='Company' || data.row.registeredType==='Member' ">
                {{ data.row.company }}
            </span>
        </template>
      </v-server-table>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {gAC} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";

import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";
import BeforeFilter from "../../BeforeFilterOpen";
import Avatar from "/src/components/Avatar";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "GiveAndAskCategoriesPostReport",
  mixins: [Mixin],
  components: {
    Avatar,
    VueJsonToCsv,
    BeforeFilter,
  },
  props: ["paramId"],
  data() {
    return {
      list: [],
      registeredTypes: [],
      reasons: [],
      uniqueArr: [],
      submitted: false,
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      alertMessage: "test",
      showAlert: false,
      dismissCountDownS: 0,
      msg: "",
      Id: this.$route.params.id,
      url: "",
      items: [],
      activePage: 1,
      selectedLetter: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      dismissCountDown: 0,
      exists: null,
      postOptions: [],
      filter: {
        post: "",
        registeredTypes: "",
        list: "",
        reason: "",
      },
      self: this,
      module: gAC,
      columns: ["registeredType", "firstName", "company", "post", "reason", "description", 'createdAt'],
      data: [],
      options: {
        headings: {
          company: this.$lang.gac.table.company,
          registeredType: this.$lang.gac.table.registeredType,
          name: this.$lang.gac.table.title,
          reason: this.$lang.gac.table.reason,
          post: this.$lang.gac.table.post,
          description: this.$lang.gac.table.description,
          createdAt: this.$lang.gac.table.added,
          firstName: this.$lang.gac.table.firstName,
        },
        editableColumns: ["firstName", "title", "description"],
        sortable: ["firstName", "title", "description"],
        filterable: ["firstName", "title", "description"],
      },
    };
  },
  created() {
    let self = this;
    //let postName : '',
    store.commit("showLoader", false);
    self.url = "/groups/report/list/" + self.Id;

  },
  mounted() {
    let self = this;
    self.$emit("changeSelected", "GiveAndAskCategoriesPostReport");
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    self.registeredTypes.push(
        {value: "Company", label: 'Company'},
        {value: "Individual", label: 'Individual'},
        {value: "Member", label: 'Member'},
        {value: "Users", label: 'All Users'}
    );

    axios.get("groups/report/list/" + self.Id).then((response) => {
      response.data.data.map(function (value, key) {
        // self.postName = this.trimfunction(value.message,20);
        self.postOptions.push({value: value.id, label: value.message});
        self.reasons.push({value: value.reasonId, label: value.reason});
      });

      self.reasons = this.removeDuplicateValues(self.reasons);

    });
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    //
    // removeDuplicate(data,key) {
    //   return [
    //       ...new Map(data.map(x=>[key(x),x])).values()
    //   ];
    // },

    checkIfExists(itemId) {
      let self = this;
      self.exists = this.items.some((item) => {
        return item.id === itemId
      })
    },

    trimfunction(str, length) {
      let str2 = "...";
      if (str.length > length) {
        let newStr = str.substring(0, length);
        return newStr.concat(str2);
      }
      if (str.length === 0) {
        return '---';
      }
      return str;
    },
    getUsers(e) {
      let type = e.value;
      this.getList(type);
    },
    getList(type) {
      let self = this;
      self.userList = "/groups/report/list/" + self.Id;
      axios.get(self.userList)
          .then((response) => {
            let responseData = response.data;
            self.list = [];
            responseData.data.map(function (value, key) {
              if (type === 'Users') {
                self.list.push({value: value.userId, label: value.firstName});
              } else if (value.registeredType === type && type === 'Company') {
                self.list.push({value: value.companyId, label: value.company});
              } else if (value.registeredType === type && type !== 'Company') {
                self.list.push({value: value.userId, label: value.name});
              }
            });
            self.list = this.removeDuplicateValues(self.list);
          });
    },

    viewPostTableRow(id) {
      let self = this;
      self.$emit("changeSelected", "GiveAndAskCategoriesPostReport");
      self.$emit("id", id);
    },


    switchFilter(show) {
      this.toggleFilterValue = show;
    },
    applyFilter() {
      let self = this;
      let reqObj = {
        postId: Object.keys(this.filter.post).length > 0 ? this.checkUndefinedNull(self.filter.post.value) : null,
        registeredType: Object.keys(this.filter.registeredTypes).length > 0 ? this.checkUndefinedNull(self.filter.registeredTypes.value) : null,
        id: Object.keys(this.filter.list).length > 0 ? this.checkUndefinedNull(self.filter.list.value) : null,
        reason: Object.keys(this.filter.reason).length > 0 ? this.checkUndefinedNull(self.filter.reason.value) : null,
      };
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();
    },
    resetFilter() {
      let self = this;
      self.filter.post = [];
      self.filter.registeredType = [];
      self.filter.list = [];
      self.filter.reason = [];

      store.commit("showLoader", true);
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
