<template>
  <CRow>
    <CCol col="12">
      <CCardHeader>
        <div class="row">
          <CCol sm="12" class="align-self-center">
            <div class="text-sm-right mt-3 mt-sm-0">
              <CButtonGroup class="file-export">
                <CButton color="secondary" disabled>
                  {{ $lang.buttons.export.title }}
                </CButton>
                <CButton
                    color="danger"
                    v-c-tooltip="$lang.buttons.export.pdf"
                    v-on:click="
                  exports(self, 'pdf', module, 'GAC Categories Post', id)
                "
                >
                  <i class="fas fa-file-pdf"></i>
                </CButton>
                <VueJsonToCsv
                    class="btn btn-success"
                    v-c-tooltip="$lang.buttons.export.excel"
                    :json-data="json_data"
                    :labels="json_label"
                    :csv-title="json_title"
                >
                  <i class="fas fa-file-excel"></i>
                </VueJsonToCsv>
              </CButtonGroup>
              <BeforeFilter :filterShow="toggleFilterValue" @getFilterToggle="switchFilter"></BeforeFilter>
            </div>
          </CCol>
        </div>
      </CCardHeader>
      <CCardBody>
        <transition name="fade">
          <CRow v-if="toggleFilterValue">

            <CCol sm="3">
              <div class="form-group">
                <label class="form__label">Registered As</label>
                <v-select :options="registeredTypes" @option:selected="getUsers"
                          v-model="gac_post.registeredTypes"></v-select>
              </div>
            </CCol>

            <CCol sm="3">
              <div class="form-group">
                <label class="form__label">Name</label>
                <v-select :options="list" v-model="gac_post.list"></v-select>
              </div>
            </CCol>
            <CCol sm="3">
              <div class="form-group">
                <label class="form__label">Categories</label>
                <v-select :options="categories" v-model="gac_post.categories"></v-select>
              </div>
            </CCol>

            <CCol sm="3">
              <div class="form-group">
                <label class="form__label">Type</label>
                <v-select :options="types" v-model="gac_post.types"></v-select>
              </div>
            </CCol>

            <CCol sm="3">
              <div class="form-group" style="margin-top: 30px">
                <CButton
                    type="submit"
                    size="md"
                    color="primary"
                    @click="applyFilter()"
                    v-c-tooltip="$lang.common.filter.button.filter"
                >
                  {{ $lang.common.filter.button.filter }}
                </CButton> &nbsp;
                <CButton color="danger" size="md" @click="resetFilter()"
                         v-c-tooltip="$lang.common.filter.button.cancel">
                  {{ $lang.common.filter.button.cancel }}
                </CButton>
              </div>
            </CCol>
          </CRow>
        </transition>
        <v-server-table
            :columns="columns"
            :url="url"
            :options="options"
            ref="myTable"
            @loaded="exports(self, 'Excel', module, 'GAC Categories Post', id)"
        >
          <template #firstName="data">
            <Avatar
                :image="data.row.avatar"
                :content="data.row.firstName"
                :isProfileListPage="true"
                :length="20"
            />
          </template>
          <template #company="data">
            <span v-if="data.row.registeredType==='Individual'">
                --
            </span>
            <span v-else-if="data.row.registeredType==='Company' || data.row.registeredType==='Member' ">
                {{ data.row.company }}
            </span>
          </template>
          <template #createdAt="data">
              {{ dateTimeUTCtoLocalFormat(data.row.createdAt) }}
          </template>

          <template #actions="data">
            <CButton
                color="warning"
                v-c-tooltip="$lang.gac.tooltip.view_comments"
                v-on:click="viewChat(data.row)"
            >
              <i class="fas fa-comment"></i>
            </CButton>
          </template>
        </v-server-table>
      </CCardBody>


    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {gAC} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted"; // https://github.com/shakee93/vue-toasted
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";
import BeforeFilter from "../../BeforeFilterOpen";
import Avatar from "/src/components/Avatar";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "giveAndAskCategoriesPost",
  mixins: [Mixin],
  components: {
    Avatar,
    BeforeFilter,
    VueJsonToCsv,
  },
  data() {
    return {
      submitted: false,
      categories: [],
      types: [],
      list: [],
      registeredTypes: [],
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      alertMessage: "test",
      showAlert: false,
      dismissCountDownS: 0,
      msg: "",
      url: "",
      items: [],
      activePage: 1,
      selectedLetter: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      dismissCountDown: 0,
      statusOptions: [],
      self: this,
      module: gAC,
      id: this.$route.params.id,
      gac_post: {
        categories: [],
        types: "",
        list: "",
        registeredTypes: "",
      },
      columns: ["registeredType", "firstName", "company", "category", "type", "message", "bookmark", 'createdAt'],
      data: [],
      options: {
        headings: {
          company: this.$lang.gac.table.company,
          registeredType: this.$lang.gac.table.registeredType,
          type: this.$lang.gac.table.type,
          message: this.$lang.gac.table.message,
          bookmark: this.$lang.gac.table.bookmark,
          firstName: this.$lang.gac.table.firstName,
          name: this.$lang.gac.table.name,
          createdAt: this.$lang.gac.table.added,
        },
        editableColumns: ["type", "message", "bookmark", "firstName", "name"],
        sortable: ["type", "message", "bookmark", "firstName", "name"],
        filterable: ["type", "message", "bookmark", "firstName", "name"],
      },
    };
  },
  created() {
    store.commit("showLoader", false);
    this.columns.push("actions");
    let self = this;
    self.url = "/groups/post/list/" + self.id;

  },
  mounted() {
    store.commit('showLoader', false); // Loader Start
    let self = this;
    self.$emit("changeSelected", "GiveAndAskCategoriesPost");
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    this.getCategories();
    self.types.push(
        {value: "Ask", label: 'Ask'},
        {value: "Give", label: 'Give'},
    );
    self.registeredTypes.push(
        {value: "Company", label: 'Company'},
        {value: "Individual", label: 'Individual'},
        {value: "Member", label: 'Member'},
        {value: "Users", label: 'All Users'}
    );
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    switchFilter(show) {
      this.toggleFilterValue = show;
    },
    getUsers(e) {
      let type = e.value;
      this.getList(type);
    },
    getList(type) {
      let self = this;
      self.userList = "/groups/post/list/" + this.$route.params.id;
      axios.get(self.userList)
          .then((response) => {
            let responseData = response.data;
            self.list = [];
            responseData.data.map(function (value, key) {
              if (type === 'Users') {
                self.list.push({value: value.userId, label: value.firstName});
              } else if (value.registeredType === type && type === 'Company') {
                self.list.push({value: value.companyId, label: value.company});
              } else if (value.registeredType === type && type !== 'Company') {
                self.list.push({value: value.userId, label: value.name});
              }
            });
            self.list = this.removeDuplicateValues(self.list);
          });
    },

    getCategories() {
      let self = this;
      self.categoryUrl = "/groups/categories/list/" + self.id;
      axios.get(self.categoryUrl)
          .then((response) => {
            let responseData = response.data;
            self.categories = [];
            responseData.data.map(function (value, key) {
              self.categories.push({value: value.categoryId, label: value.category});
            });
          });
    },

    viewPostTableRow(id) {
      let self = this;
      self.$emit("changeSelected", "GiveAndAskCategoriesPostReport");
      self.$emit("id", id);
    },
    viewChat(row) {
      let roomId = row.roomId, category = row.category, type = row.type, message = row.message;
      //  alert(category);
      localStorage.setItem('groupPostCategory', category);
      localStorage.setItem('groupPostType', type);
      localStorage.setItem('groupPostMessage', message);
      this.$router.push({path: `/groups/detail/post/chat/${(roomId)}`})
    },


    applyFilter() {
      let self = this;
      let reqObj = {
        categoryId: Object.keys(this.gac_post.categories).length > 0 ? this.checkUndefinedNull(self.gac_post.categories.value) : null,
        type: Object.keys(this.gac_post.types).length > 0 ? this.checkUndefinedNull(self.gac_post.types.value) : null,
        registeredType: Object.keys(this.gac_post.registeredTypes).length > 0 ? this.checkUndefinedNull(self.gac_post.registeredTypes.value) : null,
        id: Object.keys(this.gac_post.list).length > 0 ? this.checkUndefinedNull(self.gac_post.list.value) : null,
      };
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();
    },
    resetFilter() {
      let self = this;
      self.gac_post.categories = [];
      self.gac_post.types = [];
      self.gac_post.registeredType = [];
      self.gac_post.list = [];
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    }

  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
