<template>
  <CRow>
    <CCol col="12">
      <CCardHeader>
        <div class="row">
          <CCol sm="12" class="align-self-center">
            <div class="text-sm-right mt-3 mt-sm-0">
              <CButtonGroup class="file-export">
                <CButton color="secondary" disabled>
                  {{ $lang.buttons.export.title }}
                </CButton>
                <CButton
                    color="danger"
                    v-c-tooltip="$lang.buttons.export.pdf"
                    v-on:click="
                  exports(self, 'pdf', 'GroupUsers', 'GroupUsers', self.groupId)
                "
                >
                  <i class="fas fa-file-pdf"></i>
                </CButton>
                <VueJsonToCsv
                    class="btn btn-success"
                    v-c-tooltip="$lang.buttons.export.excel"
                    :json-data="json_data"
                    :labels="json_label"
                    :csv-title="json_title"
                >
                  <i class="fas fa-file-excel"></i>
                </VueJsonToCsv>
              </CButtonGroup>
              <BeforeFilter :filterShow="toggleFilterValue" @getFilterToggle="switchFilter"></BeforeFilter>
            </div>
          </CCol>
        </div>
      </CCardHeader>
      <CCardBody>
        <transition name="fade">
          <CRow v-if="toggleFilterValue">
            <CCol sm="4">
              <div class="form-group">
                <label class="form__label">Type</label>
                <v-select :options="types" @option:selected="getUsers" v-model="groups.types"></v-select>
              </div>
            </CCol>
            <CCol sm="4">
              <div class="form-group">
                <label class="form__label">Name</label>
                <v-select :options="list" v-model="groups.list"></v-select>
              </div>
            </CCol>
            <CCol sm="3">
              <div class="form-group" style="margin-top: 30px">
                <CButton
                    type="submit"
                    size="md"
                    color="primary"
                    @click="applyFilter()"
                    v-c-tooltip="$lang.common.filter.button.filter"
                >
                  {{ $lang.common.filter.button.filter }}
                </CButton> &nbsp;
                <CButton color="danger" size="md" @click="resetFilter()"
                         v-c-tooltip="$lang.common.filter.button.cancel">
                  {{ $lang.common.filter.button.cancel }}
                </CButton>
              </div>
            </CCol>
          </CRow>
        </transition>
        <v-server-table
            :columns="columns"
            :url="url"
            :options="options"
            ref="myTable"
            @loaded="exports(self, 'Excel', 'GroupUsers', 'GroupUsers', self.groupId)"
        >
          <template #name="data">
            <Avatar
                :image="data.row.avatar"
                :content="data.row.name"
                :isProfileListPage="true"
                :length="20"
            />
          </template>
          <template #company="data">
            <span v-if="data.row.type==='Individual'">
                --
            </span>
            <span v-else-if="data.row.type==='Company' || data.row.type==='Member' ">
                {{ data.row.company }}
            </span>
          </template>
          <template #createdAt="data">
            {{ dateTimeUTCtoLocalFormat(data.row.createdAt) }}
          </template>
        </v-server-table>
      </CCardBody>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {gAC} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted"; // https://github.com/shakee93/vue-toasted
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";
import Avatar from "/src/components/Avatar";
import BeforeFilter from "../../BeforeFilterOpen";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "GroupsUsers",
  mixins: [Mixin],
  components: {
    BeforeFilter,
    Avatar,
    VueJsonToCsv,
  },
  data() {
    return {
      submitted: false,
      groupId: '',
      groupTitle: '',
      types: [],
      list: [],
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      alertMessage: "test",
      showAlert: false,
      dismissCountDownS: 0,
      msg: "",
      url: "",
      items: [],
      activePage: 1,
      selectedLetter: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      dismissCountDown: 0,
      statusOptions: [],
      self: this,
      module: gAC,
      id: this.$route.params.id,
      groups: {
        types: {value: 'Company', 'label': 'Company'},
        list: '',
      },
      columns: ["type", "name", "title", "company", "createdAt"],
      data: [],
      options: {
        headings: {
          name: this.$lang.gac.table.name,
          title: this.$lang.gac.table.title,
          type: this.$lang.gac.table.type,
          company: this.$lang.gac.table.company,
          createdAt: this.$lang.common.table.createdAt,
        },
        editableColumns: [],
        sortable: ["name", "title", "type", "company"],
        filterable: ["name", "title", "type", "company"],
      },
    };
  },
  created() {
    let self = this;
    self.groupTitle = localStorage.getItem('groupTitle');
    store.commit("showLoader", false);
    // this.columns.push("actions");
    self.url = "/groups/users/list/" + self.id;
  },
  mounted() {
    let self = this;
    self.types.push(
        {value: "Company", label: 'Company'},
        {value: "Individual", label: 'Individual'},
        {value: "Member", label: 'Member'}
    );
    this.getList('Company');
    self.groupId = this.$route.params.id;
    self.$emit("changeSelected", "GroupsUsers");
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    getUsers(e) {
      let type = e.label;
      this.getList(type);
    },
    switchFilter(show) {
      this.toggleFilterValue = show;
    },
    viewPostTableRow(id) {
      let self = this;
      //  self.$emit("changeSelected", "GiveAndAskCategoriesPostReport");
      self.$emit("id", id);
    },

    getList(type) {
      let self = this;
      self.url = "/groups/users/list/" + this.$route.params.id;
      axios.get(self.url)
          .then((response) => {
            let responseData = response.data;
            self.list = [];
            responseData.data.map(function (value, key) {
              if (value.type === type && type === 'Company') {
                self.list.push({value: value.companyId, label: value.company});
              } else if (value.type === type && type !== 'Company') {
                self.list.push({value: value.userId, label: value.name});
              }
            });
            self.list = this.removeDuplicateValues(self.list);
          });
    },

    applyFilter() {
      let self = this;
      let reqObj = {
        type: Object.keys(this.groups.types).length > 0 ? this.checkUndefinedNull(self.groups.types.value) : null,
        id: Object.keys(this.groups.list).length > 0 ? this.checkUndefinedNull(self.groups.list.value) : null,
      };
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();
    },
    resetFilter() {
      let self = this;
      self.groups.list = [];
      self.groups.types = {value: 'Company', label: 'Company'}
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    }

  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
