<template>
  <CRow>
    <CCol col="12">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="12" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'Polls',self.userId,self.groupId)"
                  ><i class="fas fa-file-pdf"></i></CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
                <BeforeFilter
                    :filterShow="toggleFilterValue"
                    @getFilterToggle="switchFilter"
                ></BeforeFilter>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <transition name="fade">
            <CRow v-if="toggleFilterValue">

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">Registered As</label>
                  <v-select :options="registeredTypes" @option:selected="getUsers"
                            v-model="filter.registeredType"></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">Name</label>
                  <v-select :options="list" v-model="filter.list"></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">Type</label>
                  <v-select :options="types" v-model="filter.type"></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group" style="margin-top: 30px">
                  <CButton
                      type="submit"
                      size="sm"
                      color="primary"
                      @click="applyFilter()"
                      v-c-tooltip="$lang.common.filter.button.filter"
                  >
                    {{ $lang.common.filter.button.filter }}
                  </CButton>
                  &nbsp;
                  <CButton
                      color="danger"
                      size="sm"
                      @click="resetFilter()"
                      v-c-tooltip="$lang.common.filter.button.cancel"
                  >
                    {{ $lang.common.filter.button.cancel }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </transition>
          <v-server-table
              :columns="columns"
              :url="listUrl"
              :options="options"
              ref="myTable"
              @loaded="
                exports(self, 'Excel', module, 'Polls', self.userId,self.groupId)
              "
          >
            <template #firstName="data">
              <Avatar
                  :image="data.row.avatar"
                  :content="data.row.firstName"
                  :isProfileListPage="true"
                  :length="20"
              />
            </template>

            <template #name="data">
              {{ trimfunction(data.row.name, 20) }}
            </template>
            <template #endDate="data">
              {{ getDateWithFormat(data.row.endDate) }}
            </template>

            <template #company="data">
            <span v-if="data.row.registeredType==='Individual'">
                --
            </span>
              <span v-else-if="data.row.registeredType==='Company' || data.row.registeredType==='Member' ">
                {{ data.row.company }}
            </span>
            </template>

            <template #actions="data">
              <CButton
                  v-show="data.row.commentsCount"
                  color="primary"

                  v-on:click="viewComments(data.row.id,data.row.name)"
              >
                <i class="fas fa-comment"></i>
              </CButton>
              <CButton
                  v-show="data.row.totalPollResponsesCount"
                  color="primary"
                  v-on:click="viewResponses(data.row.id,data.row.name)"
              >
                <i class="fas fa-user"></i>
              </CButton>
              <CButton
                  v-show="data.row.totalPollReportsCount"
                  color="primary"
                  v-on:click="viewReports(data.row.id,data.row.name)"
              >
                <i class="fas fa-comment"></i>
              </CButton>
            </template>
          </v-server-table>

        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {gAC} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import {required} from "vuelidate/lib/validators";
import VueToasted from "vue-toasted";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";
import BeforeFilter from "../../BeforeFilterOpen";
import Avatar from "/src/components/Avatar";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

Vue.use(VueToasted);
const optionss = {
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);
export default {
  name: "PollItem",
  mixins: [Mixin],
  components: {
    Avatar,
    BeforeFilter,
    VueJsonToCsv
  },
  data() {
    return {
      groupId: '',
      userId: '',
      pollName: '',
      list: [],
      polls: [],
      types: [],
      registeredTypes: [],
      submitted: false,
      listUrl: '/groups/poll/list',
      json_data: [],
      messageColor: "success",
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      alertMessage: "",
      showAlert: false,
      dismissCountDownS: 0,
      msg: "",
      items: [],
      activePage: 1,
      selectedLetter: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      dismissCountDown: 0,
      statusOptions: [],
      self: this,
      module: gAC,
      filter: {
        type: "",
        registeredType: "",
        list: "",
        poll: "",
      },
      maxlength: {
        // category: Maxlength.gac.name,
      },
      columns: ["registeredType", "firstName", "company", "name", "optionList", "type", "endDate", "commentsCount", "totalPollResponsesCount", "actions"],
      data: [],
      options: {
        headings: {
          company: this.$lang.gac.table.company,
          registeredType: this.$lang.gac.table.registeredType,
          firstName: this.$lang.gac.table.firstName,
          name: this.$lang.poll.table.name,
          optionList: this.$lang.poll.table.options,
          type: this.$lang.poll.table.type,
          endDate: this.$lang.poll.table.endDate,
          commentsCount: this.$lang.poll.table.commentsCount,
          totalPollResponsesCount: this.$lang.poll.table.totalResponse,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["name"],
        sortable: ["name", "type", "endDate", "commentsCount", "totalPollResponsesCount"],
        filterable: ["name"],
        // see the options API
      },
    };
  },
  validations: {
    gac: {
      name: {
        required,
      },
    },
  },

  created() {

    let self = this;
    self.dismissCountDown = 0;
    if (this.$route.params.id) {
      self.groupId = this.$route.params.id;
      self.dismissCountDown = 0;
      self.listUrl = self.listUrl + "/" + this.$route.params.id;
    }
    store.commit('showLoader', false); // Loader Start
  },
  mounted() {
    store.commit('showLoader', false); // Loader Start
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    // self.data = this.loadData();
    self.list = [];
    self.registeredTypes.push(
        {value: "Company", label: 'Company'},
        {value: "Individual", label: 'Individual'},
        {value: "Member", label: 'Member'},
        {value: "Users", label: 'All Users'}
    );
    self.types.push(
        {value: "General", label: 'General'},
        {value: "Anonymous", label: 'Anonymous'}
    );


  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },

    getUsers(e) {
      let type = e.value;
      this.getList(type);
    },
    getList(type) {
      let self = this;
      self.userList = "/groups/poll/list/" + this.$route.params.id;
      axios.get(self.userList)
          .then((response) => {
            let responseData = response.data;
            self.list = [];
            responseData.data.map(function (value, key) {
              if (type === 'Users') {
                self.list.push({value: value.userId, label: value.firstName});
              } else if (value.registeredType === type && type === 'Company') {
                self.list.push({value: value.companyId, label: value.company});
              } else if (value.registeredType === type && type !== 'Company') {
                self.list.push({value: value.userId, label: value.name});
              }
            });
            self.list = this.removeDuplicateValues(self.list);
          });
    },

    applyFilter() {
      let self = this;
      let reqObj = {
        registeredType: Object.keys(this.filter.registeredType).length > 0 ? this.checkUndefinedNull(self.filter.registeredType.value) : null,
        id: Object.keys(this.filter.list).length > 0 ? this.checkUndefinedNull(self.filter.list.value) : null,
        type: Object.keys(this.filter.type).length > 0 ? this.checkUndefinedNull(self.filter.type.value) : null,
      };
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();
    },
    resetFilter() {
      let self = this;
      self.filter.registeredType = [];
      self.filter.list = [];
      self.filter.types = null;
      store.commit("showLoader", true);
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },

    createTableRow() {
    },
    viewComments(pollId, pollName) {
      localStorage.setItem('pollName', pollName);
      this.$router.push({path: `/groups/poll/comments/${pollId}`});
    },
    viewResponses(pollId, pollName) {
      localStorage.setItem('pollName', pollName);
      this.$router.push({path: `/groups/poll/comments/responses/${pollId}`});
    },
    viewReports(pollId, pollName) {
      localStorage.setItem('pollName', pollName);
      this.$router.push({path: `/groups/poll/reports/${pollId}`});
    },

  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
