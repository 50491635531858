<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          <strong>{{ groupTitle }}</strong>
        </CCardHeader>
        <CCardBody>
          <CTabs add-tab-classes="mt-1">
             <span @click="selected = 'GroupsUsers'">
              <CTab> <!-- v-if="checkPermission('view-gac-categories-post')"-->
                <template slot="title">
                  {{ $lang.gac.detail.groupUsers }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'GiveAndAskCategoriesPost'">
              <CTab v-if="checkPermission('view-gac-categories-post')">
                <template slot="title">
                  {{ $lang.gac.detail.post }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'GiveAndAskCategoriesPostReport'">
              <CTab v-if="checkPermission('view-gac-categories-post-report')">
                <template slot="title">
                  {{ $lang.gac.detail.report }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'Polls'">
              <CTab>
                <template slot="title">
                  {{ $lang.gac.detail.polls }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'Events'">
              <CTab>
                <template slot="title">
                  {{ $lang.gac.detail.events }}
                </template>
              </CTab>
            </span>
          </CTabs>
          <component
              :is="selected"
              @id="getId($event)"
              :paramId="paramId"
              @changeSelected="getTab($event)"
              style="margin-top: 20px"
          ></component>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import {Mixin} from "/src/mixins/index.js";
import GroupsUsers from "./tab/GroupsUsers";
import Polls from "./tab/Polls";
import GiveAndAskCategoriesPost from "./tab/GiveAndAskCategoriesPost";
import GiveAndAskCategoriesPostReport from "./tab/GiveAndAskCategoriesPostReport";
import Events from "./tab/Events";

export default {
  name: "GiveAndAskDetail",
  mixins: [Mixin],
  components: {
    Events,
    Polls,
    GroupsUsers,
    GiveAndAskCategoriesPost,
    GiveAndAskCategoriesPostReport,
  },
  data() {
    return {
      groupTitle: '',
      paramId: "",
      tab: "",
      showReport: "",
      tabs: ["GroupsUsers", "GiveAndAskCategoriesPost", "GiveAndAskCategoriesPostReport", "Polls", "Events"],
      selected: "GroupsUsers",
    };
  },
  created() {
    let self = this;
    self.groupTitle = localStorage.getItem('groupTitle');
  },
  methods: {
    cancel() {
      this.usersOpened
          ? this.$router.go(-1)
          : this.$router.push({path: "/gac"});
    },
    getTab(tab) {
      let self = this;
      self.selected = tab;
      self.showReport = tab !== "GiveAndAskCategoriesPost";
    },
    getId(id) {
      let self = this;
      self.paramId = id;
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
